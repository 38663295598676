@import 'tokens';
@import 'buttons';

.ngx-awesome-popup-overlay {
  .evolve-confirm-box,
  .evolve-parent-dialog {
    background: $primary-white !important;
    border-radius: $border-radius-xl !important;
  }
  .button-section {
    display: flex;
    justify-content: center;
    background: transparent !important;
    border-top: none !important;
  }
  .text-wrapper {
    font-weight: $font-weight-md;
    font-size: $font-size-lg;
    line-height: $spacing-size-xxxl;
    text-align: center;
    letter-spacing: -0.02em;
    color: $dark;
  }
  .ed-btn-danger {
    @include button-mixin;
    background: $danger;
    border: $spacing-size-xxxs solid $danger;
    padding: $spacing-size-lg calc($spacing-size-xxxl * 2);
  }
  .ed-btn-secondary {
    @include button-mixin;
    background: transparent;
    color: $primary;
    border: $spacing-size-xxxs solid $primary;
    padding: $spacing-size-lg calc($spacing-size-xxxl * 2);
  }
}

.standard-dialog {
  background: $primary-white !important;
  .button-section {
    padding-bottom: $spacing-size-xxxl !important;
  }
}

.danger-dialog {
  border-color: transparent !important;
}

.info-dialog,
.standard-dialog {
  background: $primary-white !important;
  border-color: transparent !important;
  .ed-btn-info {
    @include button-mixin;
    border: $spacing-size-xxxs solid $primary;
    padding: $spacing-size-lg calc($spacing-size-xxxl * 2);
  }
  .ed-btn-secondary {
    @include button-mixin;
    background: transparent;
    color: $primary;
    border: $spacing-size-xxxs solid $primary;
    padding: $spacing-size-lg calc($spacing-size-xxxl * 2);
  }
}
