@import './assets/scss/tokens.scss';
@import 'node_modules/@costlydeveloper/ngx-awesome-popup/styles/theme';
@import './assets/scss/dialog.scss';

html,
body {
  margin: $spacing-size-none;
  font-family: $font-base !important;
  font-size: $font-size-base !important;
  line-height: $line-height-base !important;
  height: 100vh !important;
}

input,
label,
select,
textarea {
  font-family: $font-base !important;
}

*:focus {
  outline: none;
}

p {
  margin: $spacing-size-none;
}

button,
textarea {
  font-family: $font-base !important;
  font-size: $font-size-base !important;
  line-height: $line-height-base !important;
}

.ngxp {
  &__container {
    border: $spacing-size-xxxs solid $secondary-lighter !important;
    box-shadow: 0px 6px 58px rgb(121 145 173 / 20%) !important;
    border-radius: $border-radius-md !important;
    padding: $spacing-size-xxl !important;
    background: $primary-white !important;
    z-index: $z-index-trump !important;
  }
}

.daterangepicker {
  th,
  .drp-selected,
  td {
    font-family: $font-base;
    font-size: $font-size-base;
    font-weight: $font-weight-sm;
    color: $primary;
  }
  .today {
    color: $primary !important;
    font-weight: $font-weight-ml !important;
    background-color: transparent !important;
  }
  .in-range {
    background-color: #ebf4f8 !important;
  }
  th.month {
    font-weight: $font-weight-lg;
  }
  thead {
    tr:nth-child(2) {
      th {
        background: $cray-100;
        border-radius: $border-radius-md;
      }
    }
  }
  .start-date,
  .end-date {
    background-color: $secondary-blue !important;
    color: $primary-white !important;
  }
  .applyBtn {
    @include button-mixin;
    background-color: $primary-black;
    color: $primary-white;
    display: inline-block;
    height: 40px;
    width: 140px;
  }
  .cancelBtn {
    @include button-mixin;
    background-color: $primary-white;
    color: $primary-black;
    border: $spacing-size-xxs solid $primary-black;
    display: inline-block;
    height: 40px;
    width: 140px;
  }
}

.datepicker-default {
  .controls {
    border-bottom: none;
  }
  .calendar-container {
    z-index: 100000000 !important;
    left: -170px !important;
  }
  &__input {
    width: 168px;
    height: 38px;
    padding: $spacing-size-sm;
    border: $spacing-size-xxxs solid $primary;
    border-radius: $border-radius-lg;
  }
}

.sticky-column {
  position: sticky;
  right: 0;
  z-index: $z-index-lg;
  background: $primary-white;
  height: 35px;
  &__container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    left: 0;
    align-items: center;
    justify-content: center;
    box-shadow: -6px 0px 9px 0px rgba(0, 0, 0, 0.1);
  }
}
