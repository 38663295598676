@import "tokens";

@mixin button-mixin {
  height: 56px;
  border-radius: $border-radius-lg;
  box-sizing: border-box;
  font-size: $font-size-md;
  font-weight: $font-weight-lg;
  line-height: $spacing-size-xxl;
  padding: $spacing-size-none $spacing-size-xxxl;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
}

.button {
  @include button-mixin;

  &__inner {
    display: flex;
    justify-content: center;
    white-space: nowrap;
    cursor: pointer;
  }

  &--cancel {
    background-color: $primary-white;
    color: $primary-black;
    border: $spacing-size-xxs solid $primary-black;
  }

  &--primary {
    background-color: $primary-white;
    color: $primary-black;
  }

  &--main {
    background-color: $primary-black;
    color: $primary-white;
  }

  &--stepper {
    background: $secondary;
    border: $spacing-size-xxxs solid $secondary;
    box-shadow: $spacing-size-sm $spacing-size-sm $spacing-size-xxl rgba(2, 2, 70, 0.15);
    border-radius: $border-radius-md;
    font-weight: $font-size-sm;
    line-height: $spacing-size-xxl;
    color: $primary;
  }

  &:disabled,
  &--disabled {
    opacity: 0.5;
  }

  &.active {
    background-color: $primary-lighter;
    color: $primary-white;
  }

  &--transparent {
    width: 100%;
    color: $primary-white;
    background-color: transparent;
    border: 2px solid $primary-white;
  }

  &--direction {
    background-color: transparent;
    border-radius: 50px;
    border: 1px solid $primary-lighter;
    height: 30px;
    padding: 0 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $primary-light;
  }
}
