@font-face {
  font-family: 'icomoon';
  src: url('../icons/icomoon.eot?6acuh6');
  src: url('../icons/icomoon.eot?6acuh6#iefix') format('embedded-opentype'),
    url('../icons/icomoon.ttf?6acuh6') format('truetype'),
    url('../icons/icomoon.woff?6acuh6') format('woff'),
    url('../icons/icomoon.svg?6acuh6#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-minus-circle:before {
  content: '\e92b';
  color: #ff553e;
}
.icon-plus-circle:before {
  content: '\e92c';
  color: #3751ff;
}
.icon-contracts:before {
  content: '\e92d';
  color: #d0d5dd;
}
.icon-quotes:before {
  content: '\e92a';
  color: #d0d5dd;
}
.icon-document:before {
  content: '\e922';
  color: #667085;
}
.icon-file-add:before {
  content: '\e923';
  color: #667085;
}
.icon-file-download:before {
  content: '\e924';
  color: #667085;
}
.icon-file-list-edit:before {
  content: '\e925';
  color: #667085;
}
.icon-send:before {
  content: '\e921';
  color: #667085;
}
.icon-office-trailer:before {
  color: #d0d5dd;
  content: '\e926';
}
.icon-upload:before {
  content: '\e922';
}
.icon-table-filter:before {
  content: '\e927';
}
.icon-upload:before {
  content: '\e928';
  color: #667085;
}
.icon-download:before {
  content: '\e929';
  color: #667085;
}
.icon-tag-solid:before {
  color: #d0d5dd;
  content: '\e920';
}
.icon-city:before {
  content: '\e91e';
  color: #d0d5dd;
}
.icon-sale:before {
  content: '\e91f';
  color: #d0d5dd;
}
.icon-link:before {
  content: '\e90f';
  color: #344054;
}
.icon-exclamation-mark:before {
  content: '\e91d';
  color: #667085;
}
.icon-search:before {
  content: '\e913';
  color: #344054;
}
.icon-arrow-small-right:before {
  content: '\e914';
  color: #fff;
}
.icon-mail:before {
  content: '\e915';
  color: #344054;
}
.icon-angle-right:before {
  content: '\e916';
  color: #667085;
}
.icon-right-arrow:before {
  content: '\e912';
  color: #15c0e6;
}
.icon-left-arrow:before {
  content: '\e911';
  color: #15c0e6;
}
.icon-calendar:before {
  content: '\e910';
  color: #667085;
}
.icon-close:before {
  content: '\e90d';
}
.icon-edit:before {
  content: '\e90e';
  color: #667085;
}
.icon-trash:before {
  content: '\e909';
  color: #ed1515;
}
.icon-share:before {
  content: '\e90a';
  color: #667085;
}
.icon-thumbtack:before {
  content: '\e90b';
  color: #667085;
}
.icon-list:before {
  content: '\e90c';
  color: #667085;
}
.icon-more:before {
  content: '\e908';
  color: #667085;
}
.icon-filter:before {
  content: '\e900';
  color: #667085;
}
.icon-sort:before {
  content: '\e901';
  color: #667085;
}
.icon-settings:before {
  content: '\e902';
  color: #d0d5dd;
}
.icon-reports:before {
  content: '\e903';
  color: #d0d5dd;
}
.icon-order-history:before {
  content: '\e904';
  color: #d0d5dd;
}
.icon-contacts:before {
  content: '\e905';
  color: #d0d5dd;
}
.icon-inventory:before {
  content: '\e906';
  color: #d0d5dd;
}
.icon-overview:before {
  content: '\e907';
  color: #d0d5dd;
}
